/* Helpers perso */
.mr1em {
  margin-right: 1em;
}


/* Styles spécifiques */
.info-bubble .info-bubble__chat .chat-body .btn {
  height: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.step-customization .input--file {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Message */
.site-club__intro .message{ 
  margin-top: 1em; margin-bottom: 1em;
  padding: 24px;
  background-color: #F4F9FE;
  border: 1px solid #E3ECF4;
  color: #002348;
  border-radius: 12px;
}
  .message .icon-coment-fb {
    margin-right: 1em;
  }

/* Modal */
.modal__wrapper {
  text-align: center;
}

/* Button */

/* Bouton renvoyer le lien magique - modal */
.modal__body .btn {
  padding: 16px;
  line-height: 1.5em;
}

/* Button inviter creation/etape4/verification */
button.btn.btn--primary.btn--full-admin-email {
  width: 100%;
}


@media (min-width: 768px) {
  .banner--site-club .banner__video {
    top: 60%;
  }
}

/* style sur NavLink bloqué */
.link-blocked {
  pointer-events: none;
}


@media (min-width: 1280px) {
  .card-site-club--large .card-site-club__image {
      margin-right: 14px;
  }
  .card-site-club--large {
    padding-left:14px;
  }
}


.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first ul li {
  padding-left:24px;
}

.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first ul li:before {
  margin-right:0;
  margin-left:-24px;
  position: absolute;
}

.is-hidden {
  display:none;
}

/* StepTwo */
.page-site-club .tooltip .icon {
  margin-left: 10px;
}

.icon-linkedin:before {
  content: "\e95c";
}

@media (max-width: 1279px) {
  .page-site-club .tooltip__has-overlay {
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #999; */
    background-color: rgba(0 , 0, 0, 0.5);
    /* opacity: 0.95; */
    z-index: 202;
  }

  .page-site-club .tooltip__has-overlay .tooltip__content.tooltip__content--site-club {
    opacity: unset;
  }

  .page-site-club button.tooltip--site-club {
    position: absolute;
    top: -62px;
    left: -6px;
  } 
}

@media (max-width: 767px) {
  .page-site-club button.tooltip--site-club {
    top: -83px;
    left: -6px;
  } 
}

/* StepThree */
.step-customization .site-club-container .title-with-tooltip {
  margin-top: 40px;
}

.step-customization .input--file {
  line-height: unset;
  white-space: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-customization .input--file label {
    width: 75%;
    height: unset;
}

.step-customization .input--file > div {
  position: relative;
}
  
.step-customization .site-club__sidebar.show-preview {
	overflow:hidden;
}

@media (max-width: 768px) {
  .title-with-tooltip {
    position:relative;
  }
  .site-club-container .step-checking .title-with-tooltip h3 {
    margin-right:90px;
  }
  .site-club-container .step-checking .title-with-tooltip .btn--edit {
    top: 50%;
    position: absolute;
    right: 0;
    transform: translateY(calc(-50% + 4px));
  }
}

/* Bulle - CreationAssistant */
@media (max-width: 767px) {
  .info-bubble .info-bubble__widget .more {
    display: none;
  }
}

/* COOKIE BANNER */
div.cookie-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 16px;
	padding-top: 30px;
	z-index: 10;
}

div.box.cookie {
  position: relative;
  z-index: 0;
  left: unset;
  right: unset;
  top: unset;
  bottom: unset;
  padding: 24px;
  overflow: visible;
}

button.cookie-close {
	position: absolute;
  	top: -8px;
	right: 10px;
	user-select: none;
	color: #000;
	width: unset;
	box-shadow: none;
}

button.cookie-close:hover {
	color: #000;
}

.site-club-creation-comite .modal__body p:first-child {
  padding-right:0;
}

.modal__wrapper .input{
  text-align:left;
}
@media (min-width: 768px) { 
  .cookie {
    padding-right: 10px;
    display: flex; }
    .cookie p {
      margin: auto; }
      .cookie .btn-container {
        margin-left: 80px;}
        .cookie .btn {
          position: relative;
          margin: 0.5rem;
          top: 0;
          right: 0;
          width: 200px;
          padding: 0 32px; } }
